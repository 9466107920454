export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_NAV_MENU = "SET_NAV_MENU";
export const SET_DOCTOR = "SET_DOCTOR";
export const SET_MARKETER = "SET_MARKETER";
export const SET_CORPORATE = "SET_CORPORATE";
export const SET_USER = "SET_USER";
export const SET_INSINFO = "SET_INSINFO";
export const SET_FROM = "SET_FROM";
export const SET_TO = "SET_TO";
